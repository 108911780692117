import EmpresaService from '@/service/EmpresaService'

export default {
  namespaced: true,
  state: {
    isOnline: null,
    configUnidade: null,
  },
  getters: {
    isOnline(state) {
      return state.isOnline
    },
    configUnidade(state) {
      return state.configUnidade
    },
  },
  mutations: {
    UPDATE_ONLINE(state, isOnline) {
      state.isOnline = isOnline
    },
    UPDATE_CONFIG_UNIDADE(state, configUnidade) {
      state.configUnidade = configUnidade
    },
  },
  actions: {
    async verificaOnlineStatus({ commit }, empresaId) {
      const isOnline = await EmpresaService.verificaOnline(empresaId)
      commit('UPDATE_ONLINE', isOnline)
      return isOnline
    },
    async switchOnline({ commit }, empresaId) {
      const isOnline = await EmpresaService.switchOnline(empresaId)
      commit('UPDATE_ONLINE', isOnline)
      return isOnline
    },
    async salvarConfiguracoesUnidade({ commit, state }, empresaId) {
      const configUnidade = await EmpresaService.salvarConfiguracoesUnidade(empresaId, state.configUnidade)
      commit('UPDATE_CONFIG_UNIDADE', configUnidade)
    },
    async configuracoesUnidade({ commit }, empresaId) {
      const configUnidade = await EmpresaService.configuracoesUnidade(empresaId)
      commit('UPDATE_CONFIG_UNIDADE', configUnidade)
    },
  },
}
